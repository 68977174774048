import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './css/StatusReport.css';
import API_BASE_URL from './config';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const ProductRegistrationStatus = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { productName } = location.state;
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/customers/by-product/${productName}`, { withCredentials: true });
        setCustomers(response.data.map(c => ({
            ...c,
            id: c._id // Normalize `_id` usage
        })));
      } catch (error) {
        console.error('Error fetching customers:', error);
      }
    };

    fetchCustomers();
  }, [productName]);

  const getRowColor = (customer) => {
    if (!customer._id || !customer.emailSent) {
        return 'red-row'; // Not registered
    }
    if (customer._id && customer.emailSent && !customer.readyToNotifyParticipants) {
        return 'yellow-row'; // Registered but not ready to notify participants
    }
    return 'green-row'; // Registered and ready
};

  const getRegistrationStatus = (customer) => {
    if (!customer._id || !customer.emailSent) {
      return 'Not Registered';
    }
    if (customer._id && customer.emailSent && !customer.readyToNotifyParticipants) {
      return 'Registered, Not Ready';
    }
    if (customer._id && customer.emailSent && customer.readyToNotifyParticipants) {
      return 'Rater Entry Complete';
    }
  };

  const exportToPdf = () => {
    const doc = new jsPDF();
  
    // Add the title
    doc.setFontSize(16);
    doc.text('Product Registration Status Report', 20, 20);
  
    // Add the legend
    const legendYStart = 30; // Adjust as needed
    const legendItems = [
      { color: [212, 237, 218], label: 'Rater Entry Complete' }, // Green
      { color: [255, 243, 205], label: 'Registered, Not Ready' }, // Yellow
      { color: [248, 215, 218], label: 'Not Registered' }        // Red
    ];
  
    let currentY = legendYStart;
  
    legendItems.forEach(item => {
      // Draw the color box
      doc.setFillColor(...item.color);
      doc.rect(20, currentY - 5, 5, 5, 'F'); // x, y, width, height
  
      // Add the text
      doc.setTextColor(0, 0, 0); // Black color
      doc.setFontSize(12);
      doc.text(item.label, 30, currentY);
  
      currentY += 7; // Move to the next line
    });
  
    // Adjust startY for the table to be after the legend
    const tableStartY = currentY + 10;
  
    const tableColumn = ["Leader Name", "Email", "Organization", "Registration Status"];
    const tableRows = [];
  
    customers.forEach(customer => {
      const customerData = [
        `${customer.firstName} ${customer.lastName}`,
        customer.email,
        customer.organization,
        getRegistrationStatus(customer)
      ];
      tableRows.push({
        row: customerData,
        color: getRowColor(customer)
      });
    });
  
    doc.autoTable({
      head: [tableColumn],
      body: tableRows.map(item => item.row),
      startY: tableStartY,
      styles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontSize: 10,
      },
      headStyles: {
        fillColor: [240, 240, 240],
        textColor: [0, 0, 0],
        fontStyle: 'bold',
      },
      didParseCell: function (data) {
        const rowColor = tableRows[data.row.index]?.color;
  
        if (data.section === 'body' && rowColor) {
          if (rowColor === 'red-row') {
            data.cell.styles.fillColor = [248, 215, 218];
          } else if (rowColor === 'yellow-row') {
            data.cell.styles.fillColor = [255, 243, 205];
          } else if (rowColor === 'green-row') {
            data.cell.styles.fillColor = [212, 237, 218];
          }
          data.cell.styles.textColor = [0, 0, 0]; // Ensure text is black
        }
      },
    });
  
    doc.save(`${productName}_Registration_Status_Report.pdf`);
  };  

  return (
    <div className="status-report-container">
      <h2>Product Registration Status - {productName}</h2>
      <button onClick={exportToPdf} className="export-pdf-button">Export to PDF</button>
      <button className="back-button" onClick={() => navigate('/product')}>
        Back to Product Page
      </button>
      <div className="legend">
      <span className="legend-item">
        <span className="legend-color green-box"></span> Rater Entry Complete
      </span>
      <span className="legend-item">
        <span className="legend-color yellow-box"></span> Registered, Not Ready
      </span>
      <span className="legend-item">
        <span className="legend-color red-box"></span> Not Registered
      </span>
    </div>
      <table className="status-report-table">
        <thead>
          <tr>
            <th>Leader Name</th>
            <th>Email</th>
            <th>Organization</th>
            <th>Registration Status</th>
          </tr>
        </thead>
        <tbody>
          {customers.map(customer => (
            <tr key={customer._id} className={getRowColor(customer)}>
              <td>{customer.firstName} {customer.lastName}</td>
              <td>{customer.email}</td>
              <td>{customer.organization}</td>
              <td>{getRegistrationStatus(customer)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductRegistrationStatus;
