import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from './AuthContext'; // Make sure to import the AuthContext
import './css/MainIndex.css';

const MainIndex = () => {
  const { isAdmin } = useContext(AuthContext);

  return (
    <div className="main-index-container">
      <h1>Welcome, what next?</h1>
      <ul>
        {!isAdmin && <li><Link to="/dashboard">Add or Update Raters</Link></li>}
        {!isAdmin && <li><Link to="/leader-analytics">Leader Analytics</Link></li>}
        {isAdmin && <li><Link to="/customer-maintenance">Leader Maintenance</Link></li>}
        {isAdmin && <li><Link to="/rater-maintenance">Rater Maintenance</Link></li>}
        {isAdmin && <li><Link to="/apex">Rater Readiness</Link></li>}
        {isAdmin && <li><Link to="/product">Product Maintenance</Link></li>}
        {isAdmin && <li><Link to="/ai-dashboard">AI Dashboard</Link></li>}
        {isAdmin && <li><Link to="/bulk-upload">Bulk Upload</Link></li>}
      </ul>
    </div>
  );
};

export default MainIndex;
