import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext'; // Make sure to use the correct path
import './css/Login.css';
import API_BASE_URL from './config';

const Login = () => {
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const { setUser, verifyAuth, isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_BASE_URL}/api/auth/signin`, credentials, { withCredentials: true });
      if (response.data) {
        setUser(response.data); // Set the user details received from the server
        verifyAuth(); // Refresh the auth state
  
        // Conditional navigation based on role
        const userRoles = response.data.roles;
        if (userRoles.includes('admin')) {
          navigate('/main-index'); // Navigate to the admin page
        } else {
          navigate('/dashboard'); // Navigate to the leadership dashboard
        }
        
      }
    } catch (error) {
      //console.error('Login Error:', error.response ? error.response.data : error);
      const errorMsg = error.response ? error.response.data.message : 'Login failed. Please try again.';
      setErrorMessage(errorMsg);
      console.error('Login Error:', errorMsg);
    }
  };

  useEffect(() => {
    if (isAuthenticated && location.pathname === '/login') {
      navigate('/product');
    }
  }, [isAuthenticated, navigate, location.pathname]);
  

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className="login-form">
        <input type="text" name="username" value={credentials.username} onChange={handleChange} placeholder="Username" required />
        <input type="password" name="password" value={credentials.password} onChange={handleChange} placeholder="Password" required />
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default Login;
