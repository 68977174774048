import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CSVReader from 'react-csv-reader';
import './css/BulkUpload.css';

const sampleTemplates = {
    leaders: [
      ['email', 'name', 'product'],
      ['ataunton@topical.com', 'Adam Taunton', ''],
      ['dmolly@topical.com', 'David Molly', 'LT001'],
    ],
    raters: [
      ['email', 'name', 'relationship'],
      ['cshawilliams@topical.com', 'Char Williams', 'Other'],
      ['adamtaunton@topical.com', 'Adam Taunton', 'Self'],
    ],
    products: [
      ['name', 'product'],
      ['Vera Swanson', 'L227'],
      ['John Smith', 'L315'],
    ],
  };

const BulkUploadComponent = () => {
  const [uploadType, setUploadType] = useState('');
  const [csvData, setCsvData] = useState([]);
  const [pastedCSV, setPastedCSV] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [leaders, setLeaders] = useState([]);
  const [selectedLeader, setSelectedLeader] = useState('');

  useEffect(() => {
    if (uploadType === 'raters') {
      fetchLeaders();
    }
  }, [uploadType]);

  const fetchLeaders = async () => {
    try {
      const response = await axios.get('/api/customers', { withCredentials: true });
      setLeaders(response.data);
    } catch (error) {
      console.error('Error fetching leaders:', error);
      setStatusMessage('Error fetching leaders. Please try again later.');
    }
  };

  const handleCSVUpload = (data) => {
    setCsvData(data);
    setStatusMessage(`Loaded ${data.length} rows from file.`);
  };

  const handlePasteCSV = () => {
    if (!pastedCSV.trim()) {
      setStatusMessage('No pasted data to process.');
      return;
    }
  
    try {
      const rows = pastedCSV
        .trim()
        .split('\n')
        .map((row, index) => {
          const cells = row.split(',').map(cell => cell.trim());
          if (index === 0) return null; // Skip header row
          return { Email: cells[0], Name: cells[1], Relationship: cells[2] || 'Other' };
        })
        .filter(row => row); // Remove null rows
  
      setCsvData(rows);
      setStatusMessage(`Processed ${rows.length} rows from pasted content.`);
    } catch (error) {
      console.error('Error processing pasted CSV:', error);
      setStatusMessage('Error processing pasted content. Please ensure it is valid CSV.');
    }
  };  

  const handleUpload = async () => {
    if (uploadType === 'raters' && !selectedLeader) {
      setStatusMessage('Please select a leader before uploading.');
      return;
    }
  
    if (csvData.length === 0) {
      setStatusMessage('No data to upload.');
      return;
    }
  
    if (!uploadType) {
      setStatusMessage('Please select an upload type.');
      return;
    }
  
    setIsLoading(true);
    setStatusMessage('Uploading data...');
  
    try {
      const payload = {
        data: csvData,
        leaderId: selectedLeader || null,
      };
  
      const response = await axios.post(`/api/bulk-upload/${uploadType}`, payload, {
        withCredentials: true,
      });
      setStatusMessage(`Upload successful: ${response.data.message}`);
    } catch (error) {
      console.error('Error during bulk upload:', error);
      setStatusMessage('Upload failed. Please check your data and try again.');
    } finally {
      setIsLoading(false);
    }
  };
  
  const downloadSampleCSV = () => {
    if (!uploadType) {
      setStatusMessage('Please select an upload type to download a sample.');
      return;
    }
  
    const sampleData = sampleTemplates[uploadType] || [];
    const csvContent = sampleData.map(row => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `${uploadType}-sample.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="bulk-upload-container">
      <h2>Bulk Upload</h2>

      <div className="upload-type-selection">
        <label htmlFor="upload-type">Select Upload Type:</label>
        <select
          id="upload-type"
          value={uploadType}
          onChange={(e) => setUploadType(e.target.value)}
        >
          <option value="">-- Choose Upload Type --</option>
          <option value="leaders">Leaders</option>
          <option value="raters">Raters</option>
          <option value="products">Products</option>
        </select>
      </div>

      {uploadType === 'raters' && (
        <div className="leader-selection">
          <label htmlFor="leader-select">Select a Leader:</label>
          <select
            id="leader-select"
            value={selectedLeader}
            onChange={(e) => setSelectedLeader(e.target.value)}
          >
            <option value="">-- Choose a Leader --</option>
            {leaders.map((leader) => (
              <option key={leader._id} value={leader._id}>
                {leader.firstName} {leader.lastName} ({leader.email})
              </option>
            ))}
          </select>
        </div>
      )}

      <div className="upload-options">
        <div className="file-upload">
          <CSVReader
            onFileLoaded={handleCSVUpload}
            parserOptions={{ header: true, skipEmptyLines: true }}
          />
          <p>OR</p>
        </div>
        <div className="paste-upload">
          <textarea
            placeholder="Paste CSV content here..."
            value={pastedCSV}
            onChange={(e) => setPastedCSV(e.target.value)}
            rows={6}
          ></textarea>
          <button onClick={handlePasteCSV}>Process Pasted CSV</button>
        </div>
      </div>

      <div className="upload-actions">
        <button
          className="upload-button"
          onClick={handleUpload}
          disabled={isLoading || !uploadType || (uploadType === 'raters' && !selectedLeader)}
        >
          {isLoading ? 'Uploading...' : 'Upload Data'}
        </button>
        <button
          className="sample-button"
          onClick={downloadSampleCSV}
          disabled={!uploadType}
        >
          Download Sample CSV
        </button>
      </div>

      {statusMessage && <div className="status-message">{statusMessage}</div>}
    </div>
  );
};

export default BulkUploadComponent;
