import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './css/Product.css';
import API_BASE_URL from './config';

const ProductPage = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({
    productName: '',
    launchDate: '',
    coachName: '',
    raterInviteDate: '',
    surveyEndDate: '',
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [editProductId, setEditProductId] = useState(null);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/products`);
      setProducts(response.data);
    } catch (error) {
      console.error('Failed to fetch products:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isEditMode) {
        await axios.put(`${API_BASE_URL}/api/products/${editProductId}`, product, { withCredentials: true });
        alert('Product updated successfully!');
      } else {
        await axios.post(`${API_BASE_URL}/api/products`, product, { withCredentials: true });
        alert('Product submitted successfully!');
      }
      fetchProducts();
      resetForm();
    } catch (error) {
      console.error('Failed to submit product:', error);
      alert('Failed to submit product');
    }
  };

  const handleEdit = (product) => {
    setIsEditMode(true);
    setEditProductId(product._id);
    setProduct(product);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleDeleteProduct = async (productName) => {
    try {
      await axios.delete(`${API_BASE_URL}/api/products/${productName}`, { withCredentials: true });
      alert('Product deleted successfully!');
      fetchProducts(); // Refresh product list
    } catch (error) {
      console.error('Failed to delete product:', error);
      alert(error.response?.data?.message || 'Failed to delete product.');
    }
  };

  const resetForm = () => {
    setProduct({
      productName: '',
      launchDate: '',
      coachName: '',
      raterInviteDate: '',
      surveyEndDate: '',
    });
    setIsEditMode(false);
    setEditProductId(null);
  };

  const handleRegStatus = (product) => {
    navigate('/product-registration-status', { state: { productName: product.productName } });
  };

  const handleResponseStatus = (product) => {
    navigate('/product-response-status', { state: { productName: product.productName } });
  };

  return (
    <div className="products-container">
      <div className="product-maintenance-container">
        <h1>Product Maintenance</h1>
        <form onSubmit={handleSubmit}>
          <label>
            Coach Name:
            <input type="text" name="coachName" value={product.coachName} onChange={handleChange} />
          </label>
          <label>
            Product Name:
            <input type="text" name="productName" value={product.productName} onChange={handleChange} />
          </label>
          {isEditMode && (
            <label>
              Number of Participants:
              <input
                type="number"
                name="participants"
                value={product.participants || 0}
                readOnly
              />
            </label>
          )}
          <label>
            Launch Date:
            <input type="date" name="launchDate" value={product.launchDate || ''} onChange={handleChange} />
          </label>
          <label>
            Rater Invite Date:
            <input type="date" name="raterInviteDate" value={product.raterInviteDate || ''} onChange={handleChange} />
          </label>
          <label>
            Survey End Date:
            <input type="date" name="surveyEndDate" value={product.surveyEndDate || ''} onChange={handleChange} />
          </label>
          <button type="submit">{isEditMode ? 'Update' : 'Submit'}</button>
        </form>
      </div>

      <div className="product-list-container">
        <h2>Product List</h2>
        <table className="product-list">
          <thead>
            <tr>
              <th>Coach Name</th>
              <th>Product Name</th>
              <th>Participants</th>
              <th>Leaders</th>
              <th>Rater Invite Date</th>
              <th>Survey End Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <tr key={product._id}>
                <td>{product.coachName || 'N/A'}</td>
                <td>{product.productName || 'N/A'}</td>
                <td>{product.participantCount || 0}</td>
                <td>{product.leaderCount || 0}</td>
                <td>{product.raterInviteDate ? new Date(product.raterInviteDate).toLocaleDateString() : 'N/A'}</td>
                <td>{product.surveyEndDate ? new Date(product.surveyEndDate).toLocaleDateString() : 'N/A'}</td>
                <td>
                  <div className="action-buttons">
                    <button onClick={() => handleEdit(product)}>Edit</button>
                    <button onClick={() => handleRegStatus(product)}>Reg Status</button>
                    <button onClick={() => handleResponseStatus(product)}>Response Status</button>
                    {product.participantCount === 0 && product.leaderCount === 0 ? (
                      <div
                        className="delete-icon-container"
                        title="Delete Product"
                        onClick={() => handleDeleteProduct(product.productName)}
                      >
                        🗑️
                      </div>
                    ) : (
                      <span
                        className="disabled-delete-icon"
                        title="Cannot delete product with associated participants or leaders"
                      >
                        🔒
                      </span>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductPage;
